import React, { useState, useEffect } from "react";

import './App.css';
import './Responsive.css';

import GlobalHeader from "./components/GlobalHeader";
import WebHeader from "./components/WebHeader";
import AppHeader from "./components/AppHeader";

import GlobalNav from "./components/GlobalNav";
import AppNav from "./components/AppNav";
import WebNav from "./components/WebNav";

import { HashRouter as Router, Routes, Route } from "react-router-dom";

// PAGES

// Landing
import Landing from "./pages/Landing";

//Web
import WebHome from "./pages/overview/WebHome";

import WebDesignHandoff from "./pages/overview/WebDesignHandoff";
import WebReleases from "./pages/overview/WebReleases";

import WebFoundation from "./pages/foundation/WebFoundation";
import WebColour from "./pages/foundation/WebColour";
import WebTypography from "./pages/foundation/WebTypography";
import WebGrids from "./pages/foundation/WebGrids";
import WebLayout from "./pages/foundation/WebLayouts";
import WebElevation from "./pages/foundation/WebElevation";
import WebRadius from "./pages/foundation/WebRadius";
import WebSpacers from "./pages/foundation/WebSpacers";

import WebPatterns from "./pages/patterns/WebPatterns";
import WebAEMComponents from "./pages/patterns/WebaemComponents";
import WebIcons from "./pages/patterns/WebIcons";
import WebImages from "./pages/patterns/WebImages";
import WebButtons from "./pages/patterns/WebButtons";
import WebAccordions from "./pages/patterns/WebAccordions";
import WebForms from "./pages/patterns/WebForms";

import WebTokens from "./pages/tokens/WebTokens";
import WebTokenValues from "./pages/tokens/WebTokenValues";

import WebAccessibility from "./pages/accessibility/WebAccessibility";
import WebAccessibilityOverview from "./pages/accessibility/WebAccessibilityOverview";

import WebBrand from "./pages/brand/WebBrand";
import WebBrandGlossary from "./pages/brand/WebGlossary";

import WebTutorials from "./pages/tutorials/WebTutorials";
import WebSettingUpLayouts from "./pages/tutorials/WebSettingUpLayouts";

import WebResources from "./pages/resources/WebResources";
import WebNewPageChecklist from "./pages/resources/WebNewPageChecklist";
import WebNewDesignChecklist from "./pages/resources/WebNewDesignChecklist";
import WebPublishingChecklist from "./pages/resources/WebPublishingChecklist";
import WebMarketingLandingPages from "./pages/resources/WebMarketingLandingPages";
import WebCreativeAssets from "./pages/resources/WebCreativeAssets";

//App
import AppHome from "./pages/overview/AppHome";

import AppDesignHandoff from "./pages/overview/AppDesignHandoff";
import AppReleases from "./pages/overview/AppReleases";

import AppFoundation from "./pages/foundation/AppFoundation";
import AppColour from "./pages/foundation/AppColour";
import AppTypography from "./pages/foundation/AppTypography";
import AppGrids from "./pages/foundation/AppGrids";
import AppLayout from "./pages/foundation/AppLayouts";
import AppElevation from "./pages/foundation/AppElevation";
import AppRadius from "./pages/foundation/AppRadius";

import AppPatterns from "./pages/patterns/AppPatterns";
import AppFlutterComponents from "./pages/patterns/AppFlutterComponents";
import AppNativeComponents from "./pages/patterns/AppNativeComponents";
import AppAMPAds from "./pages/patterns/AppAMP";
import AppIcons from "./pages/patterns/AppIcons";
import AppForms from "./pages/patterns/AppForms";

import AppTokens from "./pages/tokens/AppTokens";
import AppTokenValues from "./pages/tokens/AppTokenValues";

import AppAccessibility from "./pages/accessibility/AppAccessibility";
import AppAccessibilityOverview from "./pages/accessibility/AppAccessibilityOverview";

import AppBrand from "./pages/brand/AppBrand";
import AppBrandGlossary from "./pages/brand/AppGlossary";

import AppResources from "./pages/resources/AppResources";
import AppNewDesignChecklist from "./pages/resources/AppNewDesignChecklist";
import AppCreativeAssets from "./pages/resources/AppCreativeAssets";

export default function App() {
  const [isMenuBtnActive, setIsMenuBtnActive] = useState(false);
  const [showBackToTop, setShowBackToTop] = useState(false);

  const toggleMenu = () => {
    setIsMenuBtnActive(!isMenuBtnActive);
  };

  const scrollToTop = () => {
    const container = document.querySelector(".content");

    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const contentContainer = document.querySelector(".content");

      if (contentContainer) {
        setShowBackToTop(contentContainer.scrollTop > 200);
      } else {
        setShowBackToTop(window.scrollY > 200);
      }
    };

    const contentContainer = document.querySelector(".content");

    if (contentContainer) {
      contentContainer.addEventListener("scroll", handleScroll);
    } else {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (contentContainer) {
        contentContainer.removeEventListener("scroll", handleScroll);
      } else {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleClick = () => {
    toggleMenu();  // Call the toggleMenu function passed from props
    setIsMenuBtnActive(!isMenuBtnActive);
  };

  return (
    <Router>
      <>
        {showBackToTop && (
          <button className={`back-to-top ${showBackToTop ? "show" : ""}`} onClick={scrollToTop}>
            Back to Top
          </button>
        )}

        <div className={`menuBtn ${isMenuBtnActive ? 'active' : ''}`} onClick={handleClick}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
        </div>
        
        {/* Conditional Header */}
        <Routes>
          {/* Global */}
          <Route path="/" element={<GlobalHeader />} />
          {/* Web */}
          <Route path="/web-home" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-design-handoff" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-foundation" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-colour-system" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-typography" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-grids" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-layout" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-spacers" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-patterns" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-aem-components" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-images" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-buttons" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-accordions" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-forms" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-tutorials" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-setting-up-layouts" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-new-page-checklist" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-new-design-checklist" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-publishing-checklist" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-marketing-landing-pages" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-releases" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-elevation" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-border-radius" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-icons" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-tokens" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-token-values" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-accessibility" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-accessibility-overview" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-brand" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-brand-glossary" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-resources" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/web-creative-assets" element={<WebHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />

          {/* App */}
          <Route path="/app-home" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-design-handoff" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-foundation" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-colour-system" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-typography" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-grids" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-layout" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-patterns" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-forms" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-flutter-components" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-native-components" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-amp" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-releases" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-elevation" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-border-radius" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-icons" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-tokens" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-token-values" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-accessibility" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-accessibility-overview" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-brand" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-brand-glossary" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-resources" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-creative-assets" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          <Route path="/app-new-design-checklist" element={<AppHeader isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
        </Routes>

        <section className="container">

          {/* Conditional Nav */}
          <Routes>
            {/* Global */}
            <Route path="/" element={<GlobalNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            {/* Web */}
            <Route path="/web-home" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-design-handoff" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-foundation" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-colour-system" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-typography" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-grids" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-layout" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-spacers" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-patterns" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-aem-components" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-images" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-buttons" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-accordions" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-forms" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-tutorials" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-setting-up-layouts" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-new-page-checklist" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-new-design-checklist" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-publishing-checklist" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-marketing-landing-pages" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-releases" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-elevation" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-border-radius" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-icons" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-tokens" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-token-values" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-accessibility" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-accessibility-overview" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-brand" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-brand-overview" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-brand-logo" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-brand-colour-palette" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-brand-glossary" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-resources" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/web-creative-assets" element={<WebNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />

            {/* App */}
            <Route path="/app-home" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-design-handoff" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-foundation" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-colour-system" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-typography" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-grids" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-layout" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-patterns" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-forms" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-flutter-components" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-native-components" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-amp" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-releases" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-elevation" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-border-radius" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-icons" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-tokens" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-token-values" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-accessibility" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-accessibility-overview" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-brand" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-brand-overview" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-brand-logo" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-brand-colour-palette" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-brand-glossary" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-resources" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-creative-assets" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
            <Route path="/app-new-design-checklist" element={<AppNav isMenuActive={isMenuBtnActive} toggleMenu={toggleMenu} />} />
          </Routes>
          
          <div className="content">
            <div className="inner">
              {/* Conditional Pages */}
              <Routes>
                <Route path="/" element={<Landing />} />

                {/* Web */}
                <Route path="/web-home" element={<WebHome />} />
                <Route path="/web-design-handoff" element={<WebDesignHandoff />} />
                <Route path="/web-foundation" element={<WebFoundation />} />
                <Route path="/web-colour-system" element={<WebColour />} />
                <Route path="/web-typography" element={<WebTypography />} />
                <Route path="/web-grids" element={<WebGrids />} />
                <Route path="/web-layout" element={<WebLayout />} />
                <Route path="/web-spacers" element={<WebSpacers />} />
                <Route path="/web-patterns" element={<WebPatterns />} />
                <Route path="/web-aem-components" element={<WebAEMComponents />} />
                <Route path="/web-images" element={<WebImages />} />
                <Route path="/web-buttons" element={<WebButtons />} />
                <Route path="/web-accordions" element={<WebAccordions />} />
                <Route path="/web-forms" element={<WebForms />} />
                <Route path="/web-tutorials" element={<WebTutorials />} />
                <Route path="/web-setting-up-layouts" element={<WebSettingUpLayouts />} />  
                <Route path="/web-new-page-checklist" element={<WebNewPageChecklist />} />
                <Route path="/web-new-design-checklist" element={<WebNewDesignChecklist />} />
                <Route path="/web-publishing-checklist" element={<WebPublishingChecklist />} />
                <Route path="/web-marketing-landing-pages" element={<WebMarketingLandingPages />} />
                <Route path="/web-releases" element={<WebReleases />} />
                <Route path="/web-elevation" element={<WebElevation />} />
                <Route path="/web-border-radius" element={<WebRadius />} />
                <Route path="/web-icons" element={<WebIcons />} />
                <Route path="/web-tokens" element={<WebTokens />} />
                <Route path="/web-token-values" element={<WebTokenValues />} />
                <Route path="/web-accessibility" element={<WebAccessibility />} />
                <Route path="/web-accessibility-overview" element={<WebAccessibilityOverview />} />
                <Route path="/web-brand" element={<WebBrand />} />
                <Route path="/web-brand-glossary" element={<WebBrandGlossary />} />
                <Route path="/web-resources" element={<WebResources />} />
                <Route path="/web-creative-assets" element={<WebCreativeAssets />} />

                {/* App */}
                <Route path="/app-home" element={<AppHome />} />
                <Route path="/app-design-handoff" element={<AppDesignHandoff />} />
                <Route path="/app-foundation" element={<AppFoundation />} />
                <Route path="/app-colour-system" element={<AppColour />} />
                <Route path="/app-typography" element={<AppTypography />} />
                <Route path="/app-grids" element={<AppGrids />} />
                <Route path="/app-layout" element={<AppLayout />} />
                <Route path="/app-patterns" element={<AppPatterns />} />
                <Route path="/app-forms" element={<AppForms />} />
                <Route path="/app-flutter-components" element={<AppFlutterComponents />} />
                <Route path="/app-native-components" element={<AppNativeComponents />} />
                <Route path="/app-amp" element={<AppAMPAds />} />
                <Route path="/app-releases" element={<AppReleases />} />
                <Route path="/app-elevation" element={<AppElevation />} />
                <Route path="/app-border-radius" element={<AppRadius />} />
                <Route path="/app-icons" element={<AppIcons />} />
                <Route path="/app-tokens" element={<AppTokens />} />
                <Route path="/app-token-values" element={<AppTokenValues />} />
                <Route path="/app-accessibility" element={<AppAccessibility />} />
                <Route path="/app-accessibility-overview" element={<AppAccessibilityOverview />} />
                <Route path="/app-brand" element={<AppBrand />} />
                <Route path="/app-brand-glossary" element={<AppBrandGlossary />} />
                <Route path="/app-resources" element={<AppResources />} />
                <Route path="/app-creative-assets" element={<AppCreativeAssets />} /> 
                <Route path="/app-new-design-checklist" element={<AppNewDesignChecklist />} />
              </Routes>
            </div>
          </div>
        </section>
      </>
    </Router>
  );
}