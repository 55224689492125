// src/pages/WebHome.js
import React from "react";
import { NavLink } from "react-router-dom";
import ChangeLog from "../../components/ChangeLog";

// Import ChangeLogData
import ChangeLogData from "../../data/changeLogData";

const scrollToTop = () => {
  const container = document.querySelector(".content");

  if (container) {
    container.scrollTo({
      top: 0,
      behavior: "instant",
    });
  } else {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }
};

const Home = () => {
  return (
    <>
      <span className="paragraph-special-type-eyebrow">ATMOSPHERE</span>
      <h1 className="title-level-1 padding-level-3">Web Design System</h1>

      <div className="columns noWrap flex-start">
        <div className="col one_third">
          <div className="thumb thumb-1"></div>
          <h2 className="title-level-2-x-small padding-level-1">Foundation</h2>
          <p className="small">Learn about the core aspects of our design system, from grids and layout, to typography and colour scales.</p>
          <NavLink 
            to="/web-foundation" 
            className="btn tertiary"
            onClick={scrollToTop}
          >
            Learn more
          </NavLink>
        </div>
        <div className="col one_third">
          <div className="thumb thumb-2"></div>
          <h2 className="title-level-2-x-small padding-level-1">Patterns</h2>
          <p className="small">Browse available components in our design system. Discover what is available for use, and how to implement them.</p>
          <NavLink 
            to="/web-patterns" 
            className="btn tertiary"
            onClick={scrollToTop}
          >
              Learn more
          </NavLink>
        </div>
        <div className="col one_third">
          <div className="thumb thumb-3"></div>
          <h2 className="title-level-2-x-small padding-level-1">Tokens</h2>
          <p className="small">Tokens are the building blocks of our foundational elements and patterns. Learn how our tokens are composed.</p>
          <NavLink 
            to="/web-tokens" 
            className="btn tertiary"
            onClick={scrollToTop}
          >
            Learn more
          </NavLink>
        </div>
      </div>
      <div className="columns noWrap flex-start">
        <div className="col one_third">
          <div className="thumb thumb-4"></div>
          <h2 className="title-level-2-x-small padding-level-1">Accessibility</h2>
          <p className="small">Explore what Accessibility means at Air Miles and our aprroach to meeting today's web standards.</p>
          <NavLink 
            to="/web-accessibility" 
            className="btn tertiary"
            onClick={scrollToTop}
          >
            Learn more
          </NavLink>
        </div>
        <div className="col one_third">
          <div className="thumb thumb-5"></div>
          <h2 className="title-level-2-x-small padding-level-1">Brand</h2>
          <p className="small">Since 1992, we've been helping people get more back from their purchases. More travel, more rewards—more of the good stuff.</p>
          <NavLink 
            to="/web-brand" 
            className="btn tertiary"
            onClick={scrollToTop}
          >
            Learn more
          </NavLink>
        </div>
        <div className="col one_third">
          <div className="thumb thumb-6"></div>
          <h2 className="title-level-2-x-small padding-level-1">Resources</h2>
          <p className="small">Learn how to set up design files, publish pages, and how to follow website best practices with our handy checklists.</p>
          <NavLink 
            to="/web-resources" 
            className="btn tertiary"
            onClick={scrollToTop}
          >
            Learn more
          </NavLink>
        </div>
      </div>

      <div className="spacer level-3"></div>

      <hr />

      <div className="spacer level-2"></div>

      <div className="columns">
        <div className="col two_third">
          <h2 className="title-level-2-small padding-level-1">Latest release</h2>

          <ChangeLog limitToOne data={ChangeLogData} />

        </div>
        <div className="col one_third">
          <h2 className="title-level-2-small padding-level-2">Resources</h2>

          <ul className="resourceList">
            <li><a href="https://www.figma.com/design/v6oz764LRzabocmWqiFagF/1---AM-Web-Pattern-Library?node-id=0-1&t=sQV8uLXZ8EHRa2kE-1" rel="noreferrer" target="_blank" className="figma-btn">Web Pattern Library</a></li>
            <li><a href="https://www.figma.com/design/WiGbilPYipJQdvp9gNXUY9/1---Variables-%26-Tokens?node-id=30-28&t=4qS2BSmmNCXXSLpX-1" rel="noreferrer" target="_blank" className="figma-btn">Variables &amp; Tokens</a></li>
            <li><a href="https://www.figma.com/file/c8ptjKSfQBqSFoUi1uCSJC/1---Grids?type=design&node-id=30%3A28&mode=design&t=tMfycg63qDugvqGP-1" rel="noreferrer" target="_blank" className="figma-btn">Web Grids</a></li>
            <li><a href="https://www.figma.com/file/QZf8Dm0i1ym1nXmuCnTEyZ/1---Layouts?type=design&node-id=684%3A4199&mode=design&t=P5HO8TYxCouYMPE8-1" rel="noreferrer" target="_blank" className="figma-btn">Web Layouts</a></li>
            <li><a href="https://www.figma.com/file/K4nxHinPJBo178rtQWbYoe/Handoff-Components?type=design&node-id=0-1&mode=design" rel="noreferrer" target="_blank" className="figma-btn">Handoff Components</a></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Home;