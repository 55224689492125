// src/pages/overview/WebCreativeAssets.js
import React from "react";

import CreativeAssets from "../../pages/resources/CreativeAssets";

const WebCreativeAssets = () => {
  return (
    <>
      <CreativeAssets />
    </>
  );
};

export default WebCreativeAssets;