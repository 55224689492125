// src/pages/CreativeAssets.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const CreativeAssets = () => {

  const titleBarData = {
    eyebrowText: "Resources",
    pageTitle: "Creative Assets",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">AIR MILES has a full suite of creative assets, including our outstanding illustrations, branded PowerPoint template, logos, fonts, and more. <a href="https://loyaltyone-my.sharepoint.com/:f:/g/personal/jrice_loyalty_com/Egf_eUgCrxtNte2vGgU6jxcBGpW7hDd1b5lB1VRkjLEoFw" target="_blank" rel="noreferrer">Explore the library of assets here</a>.</p>
      
      <div className="drive-car-container">
        <img src={process.env.PUBLIC_URL + '/images/resources/creative-assets.png'} alt="" />
      </div>

    </>
  );
};

export default CreativeAssets;