// src/pages/AppForms.js
import React, { useState } from "react";

import SubNav from "../../components/SubNav";
import TitleBar from "../../components/TitleBar";

const Forms = () => {

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  const [activeSection, setActiveSection] = useState(null);

  const sections = [
    { id: 'display-formatting', label: 'Display Formatting' },
  ];

  const titleBarData = {
    eyebrowText: "Patterns",
    pageTitle: "Forms",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <div className="columns reverse">
        <main className="main">
          
          <h2 id="display-formatting" className="title-level-2 padding-level-2 anchor">Display Formatting</h2>

          <p className="large">For consistency we have set standards for different form element display formatting.</p>

          <div className="table">
            <table cellPadding="0" cellSpacing="0" border="none">
              <thead className="sticky">
                <tr>
                  <th>Type</th>
                  <th>Formatting</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Date</td>
                  <td>YYYY-MM-DD</td>
                </tr>
                <tr>
                  <td>Telephone</td>
                  <td>(123) 456-7890</td>
                </tr>
                <tr>
                  <td>Telephone (Masked)</td>
                  <td>(123) XXX-XXXX</td>
                </tr>
                <tr>
                  <td>Password (Masked)</td>
                  <td>••••••••••••••</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>
                    123-4567 Street Name Dr.<br />
                    City, Province
                    A1B 2C3
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="spacer level-2"></div>
        </main>

        <SubNav sections={sections} activeSection={activeSection} onSectionClick={handleSectionClick} />
      </div>
    </>
  );
};

export default Forms;
