// src/data/tokenData.js
const typographyStyleTokenData = [
    {
        "token": "--am-base-font-size-8",
        "value": "0.5 rem"
    },
    {
        "token": "--am-base-font-size-10",
        "value": "0.625 rem"
    },
    {
        "token": "--am-base-font-size-12",
        "value": "0.75 rem"
    },
    {
        "token": "--am-base-font-size-14",
        "value": "0.875 rem"
    },
    {
        "token": "--am-base-font-size-16",
        "value": "1 rem"
    },
    {
        "token": "--am-base-font-size-18",
        "value": "1.125 rem"
    },
    {
        "token": "--am-base-font-size-20",
        "value": "1.25 rem"
    },
    {
        "token": "--am-base-font-size-22",
        "value": "1.375 rem"
    },
    {
        "token": "--am-base-font-size-24",
        "value": "1.5 rem"
    },
    {
        "token": "--am-base-font-size-26",
        "value": "1.625 rem"
    },
    {
        "token": "--am-base-font-size-28",
        "value": "1.75 rem"
    },
    {
        "token": "--am-base-font-size-30",
        "value": "1.875 rem"
    },
    {
        "token": "--am-base-font-size-32",
        "value": "2 rem"
    },
    {
        "token": "--am-base-font-size-34",
        "value": "2.125 rem"
    },
    {
        "token": "--am-base-font-size-36",
        "value": "2.25 rem"
    },
    {
        "token": "--am-base-font-size-38",
        "value": "2.375 rem"
    },
    {
        "token": "--am-base-font-size-40",
        "value": "2.5 rem"
    },
    {
        "token": "--am-base-font-size-42",
        "value": "2.625 rem"
    },
    {
        "token": "--am-base-font-size-44",
        "value": "2.75 rem"
    },
    {
        "token": "--am-base-font-size-46",
        "value": "2.875 rem"
    },
    {
        "token": "--am-base-font-size-48",
        "value": "3 rem"
    },
    {
        "token": "--am-base-font-size-50",
        "value": "3.125 rem"
    },
    {
        "token": "--am-base-font-size-52",
        "value": "3.25 rem"
    },
    {
        "token": "--am-base-font-weight-100",
        "value": "100"
    },
    {
        "token": "--am-base-font-weight-200",
        "value": "200"
    },
    {
        "token": "--am-base-font-weight-300",
        "value": "300"
    },
    {
        "token": "--am-base-font-weight-400",
        "value": "400"
    },
    {
        "token": "--am-base-font-weight-500",
        "value": "500"
    },
    {
        "token": "--am-base-font-weight-600",
        "value": "600"
    },
    {
        "token": "--am-base-font-weight-700",
        "value": "700"
    },
    {
        "token": "--am-base-font-weight-800",
        "value": "800"
    },
    {
        "token": "--am-base-font-weight-900",
        "value": "900"
    },
    {
        "token": "--am-base-font-san-serif-1",
        "value": "Open Sans"
    },
    {
        "token": "--am-base-font-san-serif-2",
        "value": "Work Sans"
    },
    {
        "token": "--am-base-font-san-serif-3",
        "value": "Calibri"
    },
    {
        "token": "--am-base-font-san-serif-5",
        "value": "Arial"
    },
    {
        "token": "--am-base-font-san-serif-6",
        "value": "Helvetica"
    },
    {
        "token": "--am-base-font-san-serif-7",
        "value": "Helvetica Neue"
    },
    {
        "token": "--am-base-font-san-serif-8",
        "value": "san-serif"
    },
];

const sizingTokenData = [
    {
        "token": "--am-base-sizing-micro-1",
        "value": "0 px"
    },
    {
        "token": "--am-base-sizing-micro-2",
        "value": "1 px"
    },
    {
        "token": "--am-base-sizing-micro-3",
        "value": "2 px"
    },
    {
        "token": "--am-base-sizing-micro-4",
        "value": "3 px"
    },
    {
        "token": "--am-base-sizing-micro-5",
        "value": "4 px"
    },
    {
        "token": "--am-base-sizing-unit-1x",
        "value": "8 px"
    },
    {
        "token": "--am-base-sizing-unit-2x",
        "value": "16 px"
    },
    {
        "token": "--am-base-sizing-unit-2.5x",
        "value": "20 px"
    },
    {
        "token": "--am-base-sizing-unit-3x",
        "value": "24 px"
    },
    {
        "token": "--am-base-sizing-unit-4x",
        "value": "32 px"
    },
    {
        "token": "--am-base-sizing-unit-5x",
        "value": "40 px"
    },
    {
        "token": "--am-base-sizing-unit-6x",
        "value": "48 px"
    },
    {
        "token": "--am-base-sizing-unit-7x",
        "value": "56 px"
    },
    {
        "token": "--am-base-sizing-unit-8x",
        "value": "64 px"
    },
    {
        "token": "--am-base-sizing-unit-9x",
        "value": "72 px"
    },
    {
        "token": "--am-base-sizing-unit-10x",
        "value": "80 px"
    },
    {
        "token": "--am-base-sizing-unit-11x",
        "value": "88 px"
    },
    {
        "token": "--am-base-sizing-unit-12x",
        "value": "96 px"
    },
    {
        "token": "--am-base-sizing-unit-13x",
        "value": "104 px"
    },
    {
        "token": "--am-base-sizing-unit-14x",
        "value": "112 px"
    },
    {
        "token": "--am-base-sizing-unit-15x",
        "value": "120 px"
    },
    {
        "token": "--am-base-sizing-unit-16x",
        "value": "128 px"
    },
    {
        "token": "--am-base-sizing-unit-17x",
        "value": "136 px"
    },
    {
        "token": "--am-base-sizing-unit-18x",
        "value": "144 px"
    },
    {
        "token": "--am-base-sizing-unit-19x",
        "value": "152 px"
    },
    {
        "token": "--am-base-sizing-unit-20x",
        "value": "160 px"
    },
    {
        "token": "--am-base-sizing-unit-21x",
        "value": "168 px"
    },
    {
        "token": "--am-base-sizing-unit-22x",
        "value": "176 px"
    },
    {
        "token": "--am-base-sizing-unit-23x",
        "value": "184 px"
    },
    {
        "token": "--am-base-sizing-unit-24x",
        "value": "192 px"
    },
    {
        "token": "--am-base-sizing-unit-25x",
        "value": "200 px"
    },
    {
        "token": "--am-base-sizing-unit-26x",
        "value": "208 px"
    },
    {
        "token": "--am-base-sizing-unit-27x",
        "value": "216 px"
    },
    {
        "token": "--am-base-sizing-unit-28x",
        "value": "224 px"
    },
    {
        "token": "--am-base-sizing-unit-29x",
        "value": "232 px"
    },
    {
        "token": "--am-base-sizing-unit-30x",
        "value": "240 px"
    },
    {
        "token": "--am-base-sizing-unit-31x",
        "value": "248 px"
    },
    {
        "token": "--am-base-sizing-unit-32x",
        "value": "256 px"
    },
    {
        "token": "--am-base-sizing-unit-33x",
        "value": "264 px"
    },
    {
        "token": "--am-base-sizing-unit-34x",
        "value": "272 px"
    },
    {
        "token": "--am-base-sizing-unit-35x",
        "value": "280 px"
    },
    {
        "token": "--am-base-sizing-unit-36x",
        "value": "288 px"
    },
    {
        "token": "--am-base-sizing-unit-37x",
        "value": "296 px"
    },
    {
        "token": "--am-base-sizing-unit-38x",
        "value": "304 px"
    },
    {
        "token": "--am-base-sizing-unit-39x",
        "value": "312 px"
    },
    {
        "token": "--am-base-sizing-unit-40x",
        "value": "320 px"
    },
    {
        "token": "--am-base-sizing-unit-41x",
        "value": "328 px"
    },
    {
        "token": "--am-base-sizing-unit-42x",
        "value": "336 px"
    },
    {
        "token": "--am-base-sizing-unit-43x",
        "value": "344 px"
    },
    {
        "token": "--am-base-sizing-unit-44x",
        "value": "352 px"
    },
    {
        "token": "--am-base-sizing-unit-45x",
        "value": "360 px"
    },
    {
        "token": "--am-base-sizing-unit-46x",
        "value": "368 px"
    },
    {
        "token": "--am-base-sizing-unit-47x",
        "value": "376 px"
    },
    {
        "token": "--am-base-sizing-unit-48x",
        "value": "384 px"
    },
    {
        "token": "--am-base-sizing-unit-49x",
        "value": "392 px"
    },
    {
        "token": "--am-base-sizing-unit-50x",
        "value": "400 px"
    },
    {
        "token": "--am-base-sizing-unit-51x",
        "value": "408 px"
    },
    {
        "token": "--am-base-sizing-unit-52x",
        "value": "416 px"
    },
    {
        "token": "--am-base-sizing-unit-53x",
        "value": "424 px"
    },
    {
        "token": "--am-base-sizing-unit-54x",
        "value": "432 px"
    },
    {
        "token": "--am-base-sizing-unit-55x",
        "value": "440 px"
    },
    {
        "token": "--am-base-sizing-unit-56x",
        "value": "448 px"
    },
    {
        "token": "--am-base-sizing-percent-0",
        "value": "0%"
    },
    {
        "token": "--am-base-sizing-percent-10",
        "value": "10%"
    },
    {
        "token": "--am-base-sizing-percent-20",
        "value": "20%"
    },
    {
        "token": "--am-base-sizing-percent-30",
        "value": "30%"
    },
    {
        "token": "--am-base-sizing-percent-40",
        "value": "40%"
    },
    {
        "token": "--am-base-sizing-percent-50",
        "value": "50%"
    },
    {
        "token": "--am-base-sizing-percent-60",
        "value": "60%"
    },
    {
        "token": "--am-base-sizing-percent-70",
        "value": "70%"
    },
    {
        "token": "--am-base-sizing-percent-80",
        "value": "80%"
    },
    {
        "token": "--am-base-sizing-percent-90",
        "value": "90%"
    },
    {
        "token": "--am-base-sizing-percent-100",
        "value": "100%"
    },
    {
        "token": "--am-base-sizing-percent-125",
        "value": "125%"
    },
    {
        "token": "--am-base-sizing-percent-150",
        "value": "150%"
    },    
];

const typographyRoleTokenData = [
    {
        token: "--am-role-text-body-colour",
        value: "--am-base-colour-midnight-700"
    },
    {
        token: "--am-role-text-body-colour-on-dark",
        value: "--am-base-colour-white"
    },
    {
        token: "--am-role-text-heading-colour",
        value: "--am-base-colour-midnight-700"
    },
    {
        token: "--am-role-text-heading-colour-on-dark",
        value: "--am-base-colour-white"
    },
    {
        token: "--am-role-text-body-font-size",
        value: "--am-base-font-size-16"
    },
    {
        token: "--am-role-text-body-font-size-lg",
        value: "--am-base-font-size-20"
    },
    {
        token: "--am-role-text-body-font-size-sm",
        value: "--am-base-font-size-14"
    },
    {
        token: "--am-role-text-body-font-size-xs",
        value: "--am-base-font-size-12"
    } ,  
];

const elevationTokenData = [
    {
        token: "--am-base-elevation-1",
        value: "0px 2px 2px 0px rgba(40, 47, 64, 0.1)"
    },
    {
        token: "--am-base-elevation-2",
        value: "0px 4px 4px 0px rgba(40, 47, 64, 0.1)"
    },
    {
        token: "--am-base-elevation-3",
        value: "0px 8px 8px 0px rgba(40, 47, 64, 0.1)"
    },
];

const borderRadiusTokenData = [
    {
        token: "--am-base-radius-sm",
        value: "4px"
    },
    {
        token: "--am-base-radius-md",
        value: "8px"
    },
    {
        token: "--am-base-radius-lg",
        value: "16px"
    },
];

export { typographyStyleTokenData, sizingTokenData, typographyRoleTokenData, elevationTokenData, borderRadiusTokenData };
