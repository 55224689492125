// src/components/Header.js
import React, { } from 'react';
import ChangeLogData from "../data/changeLogData";
import { NavLink } from "react-router-dom";

export default function Header() {

  const scrollToTop = () => {
    const container = document.querySelector(".content");

    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "instant",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  };

  const scrollCollapse = () => {
    scrollToTop();
  };

  // Get the latest version from the ChangeLogData
  const latestVersion = ChangeLogData.length > 0 ? ChangeLogData[0].version : '';

  return (
    <header className="header">
        <div className='columns'>
          <NavLink 
            to="/" 
            onClick={scrollCollapse}
          >
            <img src={process.env.PUBLIC_URL + '/images/logo-full.svg'} className='logo-full' alt='AIR MILES logo' />
          </NavLink>
          <span className='platform app'>App</span>
        </div>
          
        <span className='small version'>Version {latestVersion}</span>
    </header>
  );
}
