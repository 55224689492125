// src/pages/overview/WebDesgnHandoff.js
import React from "react";

import DesignHandoff from "../../pages/overview/DesignHandoff";

const WebDesgnHandoff = () => {
  return (
    <>
      <DesignHandoff />
    </>
  );
};

export default WebDesgnHandoff;
