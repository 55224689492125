// src/pages/Landing.js
import React from "react";
import { NavLink } from "react-router-dom";

const scrollToTop = () => {
const container = document.querySelector(".content");

if (container) {
    container.scrollTo({
        top: 0,
        behavior: "instant",
        });
    } else {
        window.scrollTo({
        top: 0,
        behavior: "instant",
        });
    }
};

const Landing = () => {
  return (
    <>
        <div className="landing">
            <span className="paragraph-special-type-eyebrow">ATMOSPHERE</span>
            <h1 className="title-level-1 padding-level-2">The AIR MILES design system</h1>

            <div className="spacer level-3"></div>
            <div className="columns">
                <div className="col one_half">

                    <NavLink 
                    to="/web-home" 
                    className="choose-btn web"
                    onClick={scrollToTop}
                    >
                        <div className="graphic">
                            <img src={process.env.PUBLIC_URL + '/images/flair/white/star.svg'} alt="" className="flair-1" />
                            <img src={process.env.PUBLIC_URL + '/images/flair/white/fourPtStar.svg'} alt="" className="flair-2" />
                            <img src={process.env.PUBLIC_URL + '/images/landing/web-ds-image.png'} alt="" className="device" />
                        </div>
                        <div>
                            <h2 className="title-level-2 padding-level-1">AIR MILES web</h2>
                            <p className="large">Here you can find, guidelines, examples and best practices for designing an airmiles.ca product.</p>
                            <span className="btn tertiary">View web design system</span>
                        </div>
                    </NavLink>

                </div>
                <div className="col one_half">
                    <NavLink 
                        to="/app-home" 
                        className="choose-btn app"
                        onClick={scrollToTop}
                    >
                        <div className="graphic">
                            <img src={process.env.PUBLIC_URL + '/images/flair/white/heart.svg'} alt="" className="flair-1" />
                            <img src={process.env.PUBLIC_URL + '/images/flair/white/curlicue.svg'} alt="" className="flair-2" />
                            <img src={process.env.PUBLIC_URL + '/images/landing/app-ds-image.png'} alt="" className="device" />
                        </div>
                        <div>
                            <h2 className="title-level-2 padding-level-1">AIR MILES app</h2>
                            <p className="large">Discover patterns, guidelines, and reference material for designing the AIR MILES app.</p>
                            <span className="btn tertiary">View app design system</span>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    </>
  );
};

export default Landing;