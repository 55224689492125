// src/components/WebNav.js
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const WebNav = ({ isMenuActive }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (accordion) => {
    setActiveAccordion(activeAccordion === accordion ? null : accordion);
  };

  const scrollToTop = () => {
    const container = document.querySelector(".content");

    if (container) {
      container.scrollTo({
        top: 0,
        behavior: "instant",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  };

  const scrollCollapse = () => {
    scrollToTop();
  };

  const renderNavLink = (to, text) => (
    <li key={to}>
      <NavLink
        className={`navbar-item ${activeAccordion === to ? "is-active" : ""}`}
        activeClassName="is-active"
        to={to}
        onClick={scrollCollapse}
      >
        {text}
      </NavLink>
    </li>
  );

  return (
    <nav className={`nav ${isMenuActive ? 'active' : ''}`} role="navigation" aria-label="main navigation">
      <ul>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "overview" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("overview")}
          >
            <NavLink 
              to="/web-home" 
              className="title-level-3 overview"
              onClick={scrollCollapse}
            >
              Overview
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "overview" ? "is-active" : ""}`}>
            {renderNavLink("/web-home", "Welcome")}
            {renderNavLink("/web-design-handoff", "Design handoff")}
            {renderNavLink("/web-releases", "Releases")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "foundation" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("foundation")}
          >
            <NavLink 
              to="/web-foundation" 
              className="title-level-3 foundation"
              onClick={scrollCollapse}
            >
              Foundation
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "foundation" ? "is-active" : ""}`}>
            {renderNavLink("/web-colour-system", "Colour system")}
            {renderNavLink("/web-typography", "Typography")}
            {renderNavLink("/web-grids", "Grids")}
            {renderNavLink("/web-layout", "Layout")}
            {renderNavLink("/web-elevation", "Elevation")}
            {renderNavLink("/web-border-radius", "Radius")}
            {renderNavLink("/web-spacers", "Spacers")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "patterns" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("patterns")}
          >
            <NavLink 
              to="/web-patterns" 
              className="title-level-3 patterns"
              onClick={scrollCollapse}
            >
              Patterns
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "patterns" ? "is-active" : ""}`}>
            {renderNavLink("/web-aem-components", "AEM Components")}
            {renderNavLink("/web-icons", "Icons")}
            {renderNavLink("/web-images", "Images")}
            {renderNavLink("/web-buttons", "Buttons")}
            {renderNavLink("/web-accordions", "Accordions")}
            {renderNavLink("/web-forms", "Forms")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "tokens" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("tokens")}
          >
            <NavLink 
              to="/web-tokens" 
              className="title-level-3 tokens"
              onClick={scrollCollapse}
            >
              Tokens
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "tokens" ? "is-active" : ""}`}>
            {renderNavLink("/web-token-values", "Values")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "accessibility" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("accessibility")}
          >
            <NavLink 
              to="/web-accessibility" 
              className="title-level-3 accessibility"
              onClick={scrollCollapse}
            >
              Accessibility
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "accessibility" ? "is-active" : ""}`}>
            {renderNavLink("/web-accessibility-overview", "Overview")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "brand" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("brand")}
          >
            <NavLink 
              to="/web-brand" 
              className="title-level-3 brand"
              onClick={scrollCollapse}
            >
              Brand
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "brand" ? "is-active" : ""}`}>
            { /* renderNavLink("/web-brand-overview", "Overview") */}
            { /* renderNavLink("/web-brand-voice", "Voice") */}
            { /* renderNavLink("/web-brand-logo#overview", "Logo")*/}
            { /* renderNavLink("/web-brand-colour-palette", "Colour palette")*/}
            { /* renderNavLink("/web-brand-typography", "Typography") */}
            { /* renderNavLink("/web-brand-imagery", "Imagery") */}
            {renderNavLink("/web-brand-glossary", "Glossary")}         
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "tutorials" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("tutorials")}
          >
            <NavLink 
              to="/web-tutorials" 
              className="title-level-3 tutorials"
              onClick={scrollCollapse}
            >
              Tutorials
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "tutorials" ? "is-active" : ""}`}>
            {renderNavLink("/web-setting-up-layouts", "Setting up layouts")}
          </ul>
        </li>
        <li>
          <div
            className={`navbar-item accordion-label ${activeAccordion === "resources" ? "is-active" : ""}`}
            onClick={() => toggleAccordion("resources")}
          >
            <NavLink 
              to="/web-resources" 
              className="title-level-3 resources"
              onClick={scrollCollapse}
            >
              Resources
            </NavLink>
          </div>
          <ul className={`accordion-content ${activeAccordion === "resources" ? "is-active" : ""}`}>
            {renderNavLink("/web-new-design-checklist", "New design checklist")}
            {renderNavLink("/web-new-page-checklist", "New page checklist")}
            {renderNavLink("/web-publishing-checklist", "Publishing checklist")}
            {renderNavLink("/web-marketing-landing-pages", "Marketing Landing Pages")}
            {renderNavLink("/web-creative-assets", "Creative Assets")}
            { /* renderNavLink("/web-glossary", "Glossary") */ }
          </ul>
        </li>
        <li className="back">
        <NavLink 
          to="/app-home" 
          onClick={scrollCollapse}
        >
          App Design System
        </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default WebNav;
