// src/data/landingPageData.js
const webFoundationData = [
    {
        title: "Colour system",
        description: "Composed of functional, brand, and tier colours, Explore our colour scale, token names and usage guidelines.",
        link: "../web-colour-system",
    },
    {
        title: "Typography",
        description: "We use two typefaces in our design system: Open Sans for body copy, and Work Sans for headlines and some components. Title styles should be consistent across all screen sizes.",
        link: "../web-typography",
    },
    {
        title: "Grids",
        description: "We use a 12 column grid with percent based column and gutters. The grid is used for layout of components.",
        link: "../web-grids",
    },
    {
        title: "Layout",
        description: "Our layouts are composed of a header and footer, reusable / stackable Sections, reusable Containers, and a 12 column grid. Within this grid a library of reusable components are added inside of various column combinations and containers.",
        link: "../web-layout",
    },
    {
        title: "Elevation",
        description: "Our design system includes 3 different elevations. Learn more about our elevations, and when to use them.",
        link: "../web-elevation",
    },
    {
        title: "Radius",
        description: "Our design system includes 4 border radii. Explore common use cases when using these options.",
        link: "../web-border-radius",
    },
    {
        title: "Spacers",
        description: "Most components include default spacing below them. For more complex layouts, we use a spacer component.",
        link: "../web-spacers",
    }
];

const appFoundationData = [
    {
        title: "Colour system",
        description: "Composed of functional, brand, and tier colours, Explore our colour scale, token names and usage guidelines.",
        link: "../app-colour-system",
    },
    {
        title: "Typography",
        description: "We use two typefaces in our design system: Open Sans for body copy, and Work Sans for headlines and some components.",
        link: "../app-typography",
    },
    {
        title: "Grids",
        description: "We use a 12 column grid with percent based column and gutters. The grid is used for layout of components.",
        link: "../app-grids",
    },
    {
        title: "Layout",
        description: "We use a series of templates for different content types. These templates include variations of headings, body areas an navigation.",
        link: "../app-layout",
    },
    {
        title: "Elevation",
        description: "Our design system includes 3 different elevations. Learn more about our elevations, and when to use them.",
        link: "../app-elevation",
    },
    {
        title: "Radius",
        description: "Our design system includes 4 border radii. Explore common use cases when using these options.",
        link: "../app-border-radius",
    }
];

const webPatternsData = [
    {
        title: "AEM Components",
        description: "We use AEM as our CMS for Air Miles web, we utilize AEM's core components as well as develop custom components. Discover what components are available in AEM.",
        link: "../web-aem-components",
    },
    {
        title: "Icons",
        description: "We use Google's material design icon set at 300 weight. Icons are all on a 1:1 artboard. All icons are in an svg format. Colours / size are conditional to the components that they appear in.",
        link: "../web-icons",
    },
    {
        title: "Images",
        description: "When applying images to components and layout, we use a set number of aspect ratios. Image container scales proportionately to the selected aspect ratio.",
        link: "../web-images",
    },
    {
        title: "Buttons",
        description: "Our design system includes 3 types of CTA buttons: Primary, Secondary and Tertiary. Learn about their variants and when to use them.",
        link: "../web-buttons",
    },
    {
        title: "Accordions",
        description: "Accordions are used when content needs to be scanned quickly. Our accordions are fluid fill the width of the number of columns they are placed in.",
        link: "../web-accordions",
    },
    {
        title: "Forms",
        description: "Inputs (with mulitple types), textareas, checkboxes, radio and special input types (date, password, etc) are all included in our design system.",
        link: "../web-forms",
    }
];

const appPatternsData = [
    {
        title: "Flutter Components",
        description: "We use Flutter for Air Miles app, we utilize Flutter's core components as well as develop custom components. Discover what components are available in Flutter.",
        link: "../app-native-components",
    },
    {
        title: "Native Components",
        description: "While we use Flutter for the majority of our components, there are still scenarios where we need to utilize Native iOS and Android components.",
        link: "../app-flutter-components",
    },
    {
        title: "Icons",
        description: "We use Google's material design icon set at 300 weight. Icons are all on a 1:1 artboard. All icons are in an svg format. Colours / size are conditional to the components that they appear in.",
        link: "../app-icons",
    },
    {
        title: "AMP Ads",
        description: "AMP ads can be found throughout various AIR MILES experiences. Discover how they are implemented throughout our App.",
        link: "../app-amp",
    },
    {
        title: "Forms",
        description: "Inputs (with mulitple types), textareas, checkboxes, radio and special input types (date, password, etc) are all included in our design system.",
        link: "../app-forms",
    }
];

const webBrandData = [
    {
        title: "Glossary",
        description: "Explore a glossary of commonly used terms and definitions related to the Air Miles brand.",
        link: "../web-brand-glossary",
    }
];

const appBrandData = [
    {
        title: "Glossary",
        description: "Explore a glossary of commonly used terms and definitions related to the Air Miles brand.",
        link: "../app-brand-glossary",
    }
];

const webResourcesData = [
    {
        title: "New design checklist",
        description: "Follow these guidelines for every new figma design. These guidelines are in place to ensure proper libraries are loaded to access all components and styles.",
        link: "../web-new-design-checklist",
    },
    {
        title: "New page checklist",
        description: "This checklist should be completed for every new page prior to launch. These guidelines are in place to ensure proper SEO, on-site search and Information Architecture of our webpages.",
        link: "../web-new-page-checklist",
    },
    {
        title: "Publishing checklist",
        description: "These guidelines should be completed for the publishing of new pages. These guidelines are in place to ensure proper requirements from UX, Marketing and Anlaytics are accounted for.",
        link: "../web-Publishing-checklist",
    },
    {
        title: "Marketing landing page",
        description: "Explore Best Practices for High Conversion Rates. A landing page should serve a single purpose — the defined purpose of the campaign",
        link: "../web-marketing-landing-pages",
    },
    {
        title: "Creative Assets",
        description: "Explore illustrations, branded PowerPoint template, logos, fonts, and more",
        link: "../web-creative-assets",
    },
];

const appResourcesData = [
    {
        title: "New design checklist",
        description: "Follow these guidelines for every new figma design. These guidelines are in place to ensure proper libraries are loaded to access all components and styles.",
        link: "../app-new-design-checklist",
    },
    {
        title: "Creative Assets",
        description: "Explore illustrations, branded PowerPoint template, logos, fonts, and more",
        link: "../app-creative-assets",
    },
];

const webAccessibilityData = [
    {
        title: "Overview",
        description: "AIR MILES is committed to meeting the WCAG 2.0 Level AA success criteria.",
        link: "../web-accessibility-overview",
    },
];

const appAccessibilityData = [
    {
        title: "Overview",
        description: "AIR MILES is committed to supporting Flutter Accessibility best practices.",
        link: "../app-accessibility-overview",
    },
];

const webTokensData = [
    {
        title: "Token Values",
        description: "Explore tokens used for colours, typography, sizing, elevation and border radius",
        link: "../web-token-values",
    },
];

const appTokensData = [
    {
        title: "Token Values",
        description: "Explore tokens used for colours, typography, sizing, elevation and border radius",
        link: "../app-token-values",
    },
];

const tutorialsData = [
    {
        title: "Setting up Layouts",
        description: "Learn how to set up figma files utilizing our variable library",
        link: "../web-setting-up-layouts",
    },
];

  export { webFoundationData, webPatternsData, webAccessibilityData, appAccessibilityData, webTokensData, appTokensData, webBrandData, appBrandData, tutorialsData, webResourcesData, appResourcesData, appFoundationData, appPatternsData };
