// src/typography/TypographyBody.js
import React from "react";

const TypographyBody = () => {
  return (
    <>
    <h3 className="title-level-3 padding-level-1">Usage</h3>

    <p><strong>Spacing:</strong> include 24px padding below each paragraph.<br />
    <strong>Sizing:</strong> body copy is available in 4 font sizes.<br />
    <strong>Colour:</strong> All body copy is midnight-700 on light backgrounds and White on dark backgrounds.</p>

    <h3 className="title-level-3 padding-level-1">Inline Links</h3>

    <h4 className="title-level-4 padding-level-1">States (on light background):</h4>

    <div className="columns noWrap">
      <div className="col one_third">
        <p><strong>Default:</strong><br /> 
        <strong>color:</strong> Sapphire-900<br />
        <strong>text-decoration:</strong> underline</p>
      </div>
      <div className="col one_third">
        <p><strong>Hover:</strong><br /> 
        <strong>color:</strong> Sapphire-900<br />
        <strong>text-decoration:</strong> underline<br />
        <strong>text-decoration-thickness:</strong> 2px</p>
      </div>
      <div className="col one_third">
        <p><strong>Focus:</strong><br /> 
        <strong>color:</strong> Sapphire-900<br />
        <strong>text-decoration:</strong> underline<br />
        <strong>outline:</strong> 2px solid emerald-900</p>
      </div>
    </div>

    <div className="spacer level-2"></div>

    <div className="callout white">
      <div className="columns">
        <div className="col one_fifth">
          <p className="small"><strong>Default State</strong></p>
        </div>
        <div className="col four_fifth">
          <p className="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum eleifend varius. <span className="link">This isa link - Click me</span>. Curabitur vitae nunc nec diam pellentesque tincidunt. Vestibulum rutrum finibus orci, ac molestie risus consectetur id.</p>
        </div>
      </div>

      <div className="columns">
        <div className="col one_fifth">
          <p className="small"><strong>Hover State</strong></p>
        </div>
        <div className="col four_fifth">
          <p className="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum eleifend varius. <span className="link hover">This isa link - Click me</span>. Curabitur vitae nunc nec diam pellentesque tincidunt. Vestibulum rutrum finibus orci, ac molestie risus consectetur id.</p>
        </div>
      </div>

      <div className="columns">
        <div className="col one_fifth">
          <p className="small"><strong>Focus State</strong></p>
        </div>
        <div className="col four_fifth">
          <p className="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum eleifend varius. <span className="link focus">This isa link - Click me</span>. Curabitur vitae nunc nec diam pellentesque tincidunt. Vestibulum rutrum finibus orci, ac molestie risus consectetur id.</p>
        </div>
      </div>
    </div>

    <div className="spacer level-4"></div>

    <h4 className="title-level-4 padding-level-1">States (on dark background - Midnight-700):</h4>

    <div className="columns noWrap">
      <div className="col one_third">
        <p><strong>Default:</strong><br /> 
        <strong>color:</strong> Electric-lime-500<br />
        <strong>text-decoration:</strong> underline</p>
      </div>
      <div className="col one_third">
        <p><strong>Hover:</strong><br /> 
        <strong>color:</strong> Electric-lime-500<br />
        <strong>text-decoration:</strong> underline<br />
        <strong>text-decoration-thickness:</strong> 2px</p>
      </div>
      <div className="col one_third">
        <p><strong>Focus:</strong><br /> 
        <strong>color:</strong> Electric-lime-500<br />
        <strong>text-decoration:</strong> underline<br />
        <strong>outline:</strong> 2px solid emerald-900</p>
      </div>
    </div>

    <div className="spacer level-2"></div>

    <div className="callout dark extra">
      <div className="columns">
        <div className="col one_fifth">
          <p className="small"><strong>Default State</strong></p>
        </div>
        <div className="col four_fifth">
          <p className="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum eleifend varius. <span className="link">This isa link - Click me</span>. Curabitur vitae nunc nec diam pellentesque tincidunt. Vestibulum rutrum finibus orci, ac molestie risus consectetur id.</p>
        </div>
      </div>

      <div className="columns">
        <div className="col one_fifth">
          <p className="small"><strong>Hover State</strong></p>
        </div>
        <div className="col four_fifth">
          <p className="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum eleifend varius. <span className="link hover">This isa link - Click me</span>. Curabitur vitae nunc nec diam pellentesque tincidunt. Vestibulum rutrum finibus orci, ac molestie risus consectetur id.</p>
        </div>
      </div>

      <div className="columns">
        <div className="col one_fifth">
          <p className="small"><strong>Focus State</strong></p>
        </div>
        <div className="col four_fifth">
          <p className="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum eleifend varius. <span className="link focus">This isa link - Click me</span>. Curabitur vitae nunc nec diam pellentesque tincidunt. Vestibulum rutrum finibus orci, ac molestie risus consectetur id.</p>
        </div>
      </div>
    </div>

    <div className="spacer level-2"></div>

    <div className="callout white">
      <div className="tag-dont">Don't Do</div>
      <p>Our CMS uses tokens to identify if a background of a container or section is light or dark. Our light backgrounds include "Sapphire-100", "Sapphire-200", "White", "Lavender-400" and "Oatmylk-100". On light backgrounds text changes to Midnight-700 and inline links to Sapphire-900 based on the token "on-light". There is a known issue that Sapphire-900 does not pass accessibility requirements on Lavender-400 with normal sized text.</p>
      <p><strong>Designs MUST NOT include inline links on Lavender-400 backgrounds</strong>.</p>
    </div>

    <div className="spacer level-4"></div>

    <h4 className="title-level-4 padding-level-1">States (on dark background - Sapphire-900):</h4>

    <div className="columns noWrap">
      <div className="col one_third">
        <p><strong>Default:</strong><br /> 
        <strong>color:</strong> Electric-lime-300<br />
        <strong>text-decoration:</strong> underline</p>
      </div>
      <div className="col one_third">
        <p><strong>Hover:</strong><br /> 
        <strong>color:</strong> Electric-lime-300<br />
        <strong>text-decoration:</strong> underline<br />
        <strong>text-decoration-thickness:</strong> 2px</p>
      </div>
      <div className="col one_third">
        <p><strong>Focus:</strong><br /> 
        <strong>color:</strong> Electric-lime-300<br />
        <strong>text-decoration:</strong> underline<br />
        <strong>outline:</strong> 2px solid emerald-900</p>
      </div>
    </div>

    <div className="spacer level-2"></div>

    <div className="callout dark">
      <div className="columns">
        <div className="col one_fifth">
          <p className="small"><strong>Default State</strong></p>
        </div>
        <div className="col four_fifth">
          <p className="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum eleifend varius. <span className="link">This isa link - Click me</span>. Curabitur vitae nunc nec diam pellentesque tincidunt. Vestibulum rutrum finibus orci, ac molestie risus consectetur id.</p>
        </div>
      </div>

      <div className="columns">
        <div className="col one_fifth">
          <p className="small"><strong>Hover State</strong></p>
        </div>
        <div className="col four_fifth">
          <p className="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum eleifend varius. <span className="link hover">This isa link - Click me</span>. Curabitur vitae nunc nec diam pellentesque tincidunt. Vestibulum rutrum finibus orci, ac molestie risus consectetur id.</p>
        </div>
      </div>

      <div className="columns">
        <div className="col one_fifth">
          <p className="small"><strong>Focus State</strong></p>
        </div>
        <div className="col four_fifth">
          <p className="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum eleifend varius. <span className="link focus">This isa link - Click me</span>. Curabitur vitae nunc nec diam pellentesque tincidunt. Vestibulum rutrum finibus orci, ac molestie risus consectetur id.</p>
        </div>
      </div>
    </div>

    <div className="spacer level-2"></div>

    <div className="callout white">
      <div className="tag-dont">Don't Do</div>
      <p>Our CMS uses tokens to identify if a background of a container or section is light or dark. Our dark backgrounds include "Sapphire-900" and "Midnight-700". On dark backgrounds text changes to white and inline links to Electric-Lime-500 based on the token "on-dark". There is a known issue that Electric-lime-500 does not pass accessibility requirements on Sapphire-900 with normal sized text. For Sapphire-900 background we use Electric-Lime-300.</p>
      <p><strong>This is currently not available in our CMS. Designs MUST NOT include inline links on Sapphire-900 backgrounds</strong>.</p>
    </div>
    </>
  );
};

export default TypographyBody;