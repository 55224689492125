// src/pages/AppRadius.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const Radius = () => {

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Border radius",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">Coming soon.</p>
    </>
  );
};

export default Radius;
