// src/pages/Icons.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const Icons = () => {
  const titleBarData = {
    eyebrowText: "Patterns",
    pageTitle: "Icons",
    figmaLink: "https://www.figma.com/file/WiGbilPYipJQdvp9gNXUY9/1---Colours%2C-Effects-%26-Typography?type=design&node-id=2382-2288&mode=design",
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">We use Google's material design icon set at 300 weight. Icons are all on a 1:1 artboard. All icons are in an svg format. Colours / size are conditional to the components that they appear in. Functional icons are available in 3 colours: <span className="highlight-midnight">Midnight 700</span>, <span className="highlight-sapphire">Sapphire 900</span> and <span className="highlight-white">White</span></p>
    
      <div className="spacer level-2"></div>

      <div className="columns">
        <h2 className="title-level-2 padding-level-2">airmliles.ca</h2>
        <a href={process.env.PUBLIC_URL + '/downloads/functional-icons.zip'} className="btn small">Download icons</a>
      </div>

      <div className="columns wrap noWrap flex-start">
        <div className="col one_third">
          <h3 className="title-level-3-large padding-level-2">Indicators</h3>
          <ul className="icon-grid">
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/home_filled.svg'} alt="" title="home_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/Offers_filled.svg'} alt="" title="Offers_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/receipt_long.svg'} alt="" title="receipt_long" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/x_circle.svg'} alt="" title="x_circle" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/add_task.svg'} alt="" title="add_task" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/arrow_selector_tool.svg'} alt="" title="arrow_selector_tool" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/badge.svg'} alt="" title="badge" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/barcode_reader.svg'} alt="" title="barcode_reader" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/barcode_scanner.svg'} alt="" title="barcode_scanner" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/check_circle.svg'} alt="" title="check_circle" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/check_small.svg'} alt="" title="check_small" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/check.svg'} alt="" title="check" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/close_small.svg'} alt="" title="close_small" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/close.svg'} alt="" title="close" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/day.svg'} alt="" title="day" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/dislike.svg'} alt="" title="dislike" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/elipse.svg'} alt="" title="elipse" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/heart_empty.svg'} alt="" title="heart_empty" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/heart_full.svg'} alt="" title="heart_full" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/like.svg'} alt="" title="like" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/location_user.svg'} alt="" title="location_user" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/location.svg'} alt="" title="location" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/lock_closed.svg'} alt="" title="lock_closed" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/lock_open.svg'} alt="" title="lock_open" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/morning.svg'} alt="" title="morning" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/night.svg'} alt="" title="night" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/offers.svg'} alt="" title="offers" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/pending.svg'} alt="" title="pending" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/quote.svg'} alt="" title="quote" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/saved.svg'} alt="" title="saved" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/tag_loyalty.svg'} alt="" title="tag_loyalty" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/task_alt.svg'} alt="" title="task_alt" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/visibility_off.svg'} alt="" title="visibility_off" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/visibility.svg'} alt="" title="visibility" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/bookmark_check.svg'} alt="" title="bookmark check" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/bookmark_filled.svg'} alt="" title="bookmark filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/bookmark_plus.svg'} alt="" title="bookmark plus" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/heart_small_fill.svg'} alt="" title="heart small fill" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/heart_small_outline_bold.svg'} alt="" title="heart small outlined bold" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/heart_small.svg'} alt="" title="heart small" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/location_alt.svg'} alt="" title="location alt" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/offer_tag_bonus.svg'} alt="" title="offer tag bonus" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/offer_tag_optin.svg'} alt="" title="offer tag opt in" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/offer_tag_qrcode.svg'} alt="" title="offer tag qrcode" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/release_alert.svg'} alt="" title="release alert" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/rewards.svg'} alt="" title="rewards" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/secure.svg'} alt="" title="secure" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/travel_information.svg'} alt="" title="travel information" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/directions_run.svg'} alt="" title="directions run" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/follow_the_signs.svg'} alt="" title="follow the signs" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/rewards_full.svg'} alt="" title="rewards full" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/location_filled.svg'} alt="" title="location filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/loyalty.svg'} alt="" title="loyalty" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/cloud_off_filled.svg'} alt="" title="cloud_off_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/visibility_off_filled.svg'} alt="" title="visibility_off_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/problem_filled.svg'} alt="" title="problem_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/lock_filled.svg'} alt="" title="lock_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/server_error_filled.svg'} alt="" title="server_error_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/event_busy_filled.svg'} alt="" title="event_busy" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/link.svg'} alt="" title="link" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/unlink.svg'} alt="" title="unlink" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/elipse_vertical.svg'} alt="" title="elipse_vertical" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/all.svg'} alt="" title="all" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/chart_data.svg'} alt="" title="chart_data" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/event_busy_empty.svg'} alt="" title="event_busy_empty" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/volume_up.svg'} alt="" title="volume_up" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/indicators/volume_off.svg'} alt="" title="volume_off" /></li>
          </ul>
        </div>
        <div className="col one_third">
          <h3 className="title-level-3-large padding-level-2">Utility</h3>
          <ul className="icon-grid">
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/copy.svg'} alt="" title="copy" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/date_range.svg'} alt="" title="date_range" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/settings.svg'} alt="" title="settings" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/terms.svg'} alt="" title="terms" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/calendar.svg'} alt="" title="calendar" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/close_small.svg'} alt="" title="close_small" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/close.svg'} alt="" title="close" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/duplicate.svg'} alt="" title="duplicate" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/edit.svg'} alt="" title="edit" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/filter.svg'} alt="" title="filter" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/grid_view.svg'} alt="" title="grid_view" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/list_view.svg'} alt="" title="list_view" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/map.svg'} alt="" title="map" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/menu_button.svg'} alt="" title="menu_button" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/minimize.svg'} alt="" title="minimize" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/minus.svg'} alt="" title="minus" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/pause.svg'} alt="" title="pause" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/play.svg'} alt="" title="play" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/plus.svg'} alt="" title="plus" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/print.svg'} alt="" title="print" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/refresh.svg'} alt="" title="refresh" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/search.svg'} alt="" title="search" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/share.svg'} alt="" title="share" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/sort.svg'} alt="" title="sort" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/trash.svg'} alt="" title="trash" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/circle_plus.svg'} alt="" title="circle plus" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/wrench.svg'} alt="" title="wrench" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/form.svg'} alt="" title="form" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/missing_miles.svg'} alt="" title="missing miles" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/shield_lock.svg'} alt="" title="shield lock" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/filter_alt_off_filled.svg'} alt="" title="filter alt off filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/description_filled.svg'} alt="" title="description filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/copy_filled.svg'} alt="" title="copy filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/local_atm.svg'} alt="" title="local_atm" /></li>

            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/dashboard.svg'} alt="" title="dashboard" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/utility/transactions.svg'} alt="" title="transactions" /></li>
          </ul>
        </div>
        <div className="col one_third">
        <h3 className="title-level-3-large padding-level-2">Other</h3>
          <ul className="icon-grid">
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/rewards_filled.svg'} alt="" title="rewards_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/add_card.svg'} alt="" title="add_card" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/balance.svg'} alt="" title="balance" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/browse_rewards.svg'} alt="" title="browse_rewards" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/credit_card.svg'} alt="" title="credit_card" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/custom.svg'} alt="" title="custom" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/finance.svg'} alt="" title="finance" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/fuel.svg'} alt="" title="fuel" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/garden.svg'} alt="" title="garden" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/generic_icon.svg'} alt="" title="generic_icon" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/grocery.svg'} alt="" title="grocery" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/home.svg'} alt="" title="home" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/liquor.svg'} alt="" title="liquor" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/online.svg'} alt="" title="online" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/pharmacy.svg'} alt="" title="pharmacy" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/receipt.svg'} alt="" title="receipt" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/restaurant.svg'} alt="" title="restaurant" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/rewards.svg'} alt="" title="rewards" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/shopping_bag.svg'} alt="" title="shopping_bag" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/transfer.svg'} alt="" title="transfer" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/offer_tag_code.svg'} alt="" title="offer tag code" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/placeholder.svg'} alt="" title="placeholder" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/cash_register.svg'} alt="" title="cash register" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/business.svg'} alt="" title="business" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/present.svg'} alt="" title="present" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/local_mall_filled.svg'} alt="" title="local mall filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/storefront_filled.svg'} alt="" title="storefront_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/storefront_off_filled.svg'} alt="" title="storefront_off_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/view_in_ar.svg'} alt="" title="view_in_ar" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/attractions.svg'} alt="" title="attractions" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/donate.svg'} alt="" title="donate" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/personal_shopper.svg'} alt="" title="personal_shopper" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/storefront_empty.svg'} alt="" title="storefront_empty" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/other/storefront_off_empty.svg'} alt="" title="storefront_off_empty" /></li>
          </ul>
        </div>
        <div className="col one_third">
          <h3 className="title-level-3-large padding-level-2">Arrows</h3>
          <ul className="icon-grid">
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/arrow_down.svg'} alt="" title="arrow_down" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/arrow_left.svg'} alt="" title="arrow_left" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/arrow_right.svg'} alt="" title="arrow_right" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/arrow_up.svg'} alt="" title="arrow_up" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/back_arrow.svg'} alt="" title="back_arrow" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/chevron_double_left.svg'} alt="" title="chevron_double_left" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/chevron_double_right.svg'} alt="" title="chevron_double_right" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/chevron_left.svg'} alt="" title="chevron_left" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/chevron_right.svg'} alt="" title="chevron_right" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/expand_less.svg'} alt="" title="expand_less" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/expand_more.svg'} alt="" title="expand_more" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/external_link.svg'} alt="" title="external_link" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/forward_arrow.svg'} alt="" title="forward_arrow" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/double_arrows.svg'} alt="" title="double arrow" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/arrows_outward.svg'} alt="" title="arrows_outward" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/arrows/arrows_outward_joined.svg'} alt="" title="arrows_outward_joined" /></li>
          </ul>
        </div>
        <div className="col one_third">
          <h3 className="title-level-3-large padding-level-2">Notifications</h3>
          <ul className="icon-grid">
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/bell_solid.svg'} alt="" title="bell_solid" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/bell.svg'} alt="" title="bell" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/check_default.svg'} alt="" title="check_default" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/check_filled.svg'} alt="" title="check_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/critical_default.svg'} alt="" title="critical_default" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/error_default.svg'} alt="" title="error_default" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/error_filled.svg'} alt="" title="error_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/featured_rewards.svg'} alt="" title="featured_rewards" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/help.svg'} alt="" title="help" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/info_filled.svg'} alt="" title="info_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/info_outlined.svg'} alt="" title="info_outlined" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/megaphone.svg'} alt="" title="megaphone" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/warning_default.svg'} alt="" title="warning_default" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/warning_filled.svg'} alt="" title="warning_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/block.svg'} alt="" title="block" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/critical_filled.svg'} alt="" title="critical_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/question_mark.svg'} alt="" title="question_mark" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/notifications_paused_filled.svg'} alt="" title="notifications paused filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/help_filled.svg'} alt="" title="help_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/notifications/cancel_filled.svg'} alt="" title="cancel_filled" /></li>
          </ul>
        </div>
        <div className="col one_third">
          <h3 className="title-level-3-large padding-level-2">User</h3>
          <ul className="icon-grid">
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/account.svg'} alt="" title="account" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/badge.svg'} alt="" title="badge" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/my_orders.svg'} alt="" title="my_orders" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/password.svg'} alt="" title="password" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/person.svg'} alt="" title="person" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/signature.svg'} alt="" title="signature" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/update_profile.svg'} alt="" title="update_profile" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/user_app.svg'} alt="" title="user app" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/person_edit.svg'} alt="" title="person edit" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/passkey.svg'} alt="" title="passkey" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/user/account_filled.svg'} alt="" title="accout_filled" /></li>
          </ul>
        </div>
        <div className="col one_third">
          <h3 className="title-level-3-large padding-level-2">Contact</h3>
          <ul className="icon-grid">
            <li><img src={process.env.PUBLIC_URL + '/images/icons/contact/call.svg'} alt="" title="call" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/contact/chat_bubble.svg'} alt="" title="chat_bubble" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/contact/chat.svg'} alt="" title="chat" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/contact/mail_open.svg'} alt="" title="mail_open" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/contact/mail.svg'} alt="" title="mail" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/contact/robot.svg'} alt="" title="robot" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/contact/support_agent.svg'} alt="" title="support_agent" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/contact/call_outline.svg'} alt="" title="call outline" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/contact/mail_filled.svg'} alt="" title="mail_filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/contact/contact_phone.svg'} alt="" title="contact_phone" /></li>
          </ul>
        </div>
        <div className="col one_third">
          <h3 className="title-level-3-large padding-level-2">Brand</h3>
          <ul className="icon-grid">
            <li><img src={process.env.PUBLIC_URL + '/images/icons/brand/cash_miles_fill.svg'} alt="" title="cash_miles filled" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/brand/cash_miles.svg'} alt="" title="cash_miles" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/brand/dream_miles_fill.svg'} alt="" title="dream_miles filed" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/brand/dream_miles.svg'} alt="" title="dream_miles" /></li>
            <li><img src={process.env.PUBLIC_URL + '/images/icons/brand/tier_benefits.svg'} alt="" title="tier_benefits" /></li>
          </ul>
        </div>
      </div>

      <div className="spacer level-4"></div>

      <h3 className="title-level-2 padding-level-2">Travel</h3>
      
      <p>Currently the <strong>Travel website</strong> uses custom icons. The following icons are to replace these icons in future releases to create a cohesive message across products.</p>

      <ul className="icon-grid">
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/balance.svg'} alt="" title="balance" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/bookmark_manager.svg'} alt="" title="bookmark_manager" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/bundles.svg'} alt="" title="bundles" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/call.svg'} alt="" title="call" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/car.svg'} alt="" title="car" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/card.svg'} alt="" title="card" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/confirmation_number.svg'} alt="" title="confirmation_number" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/credit_card.svg'} alt="" title="credit_card" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/cruise.svg'} alt="" title="cruise" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/diamond.svg'} alt="" title="diamond" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/featured_seasonal_and_gifts.svg'} alt="" title="featured_seasonal_and_gifts" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/flights_and_hotels.svg'} alt="" title="flights_and_hotels" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/history.svg'} alt="" title="history" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/hotel_class.svg'} alt="" title="hotel_class" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/hotel.svg'} alt="" title="hotel" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/person_alert.svg'} alt="" title="person_alert" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/person.svg'} alt="" title="person" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/photo_camera.svg'} alt="" title="photo_camera" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/plane_ticket.svg'} alt="" title="plane_ticket" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/storefront.svg'} alt="" title="storefront" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/travel/travel_bookings.svg'} alt="" title="travel_bookings" /></li>
      </ul>

      <div className="spacer level-4"></div>

      <h3 className="title-level-2 padding-level-2">Merchandise</h3>

      <p>Currently the <strong>Merchandise website</strong> uses custom icons. The following icons are to replace these icons in future releases to create a cohesive message across products.</p>

      <ul className="icon-grid">
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/acute.svg'} alt="" title="acute" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/add_to_queue.svg'} alt="" title="add_to_queue" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/agriculture.svg'} alt="" title="agriculture" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/aod_watch.svg'} alt="" title="aod_watch" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/backpack.svg'} alt="" title="backpack" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/balance.svg'} alt="" title="balance" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/barcode_scanner.svg'} alt="" title="barcode_scanner" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/beach_access.svg'} alt="" title="beach_access" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/bed.svg'} alt="" title="bed" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/blender.svg'} alt="" title="blender" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/breakfast_dining.svg'} alt="" title="breakfast_dining" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/calculate.svg'} alt="" title="calculate" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/call.svg'} alt="" title="call" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/camping.svg'} alt="" title="camping" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/card_travel.svg'} alt="" title="card_travel" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/chair.svg'} alt="" title="chair" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/check.svg'} alt="" title="check" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/confirmation_number.svg'} alt="" title="confirmation_number" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/contract_edit.svg'} alt="" title="contract_edit" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/credit_card_heart.svg'} alt="" title="credit_card_heart" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/crib.svg'} alt="" title="crib" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/deck.svg'} alt="" title="deck" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/delete.svg'} alt="" title="delete" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/description-1.svg'} alt="" title="description-1" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/description.svg'} alt="" title="description" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/desktop_windows-1.svg'} alt="" title="desktop_windows-1" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/desktop_windows.svg'} alt="" title="desktop_windows" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/device_thermostat.svg'} alt="" title="device_thermostat" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/directions_boat.svg'} alt="" title="directions_boat" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/directions_car.svg'} alt="" title="directions_car" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/distance.svg'} alt="" title="distance" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/downhill_skiing.svg'} alt="" title="downhill_skiing" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/draft.svg'} alt="" title="draft" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/drafts.svg'} alt="" title="drafts" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/event_repeat.svg'} alt="" title="event_repeat" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/exclamation.svg'} alt="" title="exclamation" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/exercise.svg'} alt="" title="exercise" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/eyeglasses.svg'} alt="" title="eyeglasses" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/favorite.svg'} alt="" title="favorite" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/flightsmode.svg'} alt="" title="flightsmode" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/grass.svg'} alt="" title="grass" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/handyman.svg'} alt="" title="handyman" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/headphones.svg'} alt="" title="headphones" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/help.svg'} alt="" title="help" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/hotel_class.svg'} alt="" title="hotel_class" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/hotel.svg'} alt="" title="hotel" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/import_contacts.svg'} alt="" title="import_contacts" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/info.svg'} alt="" title="info" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/inventory.svg'} alt="" title="inventory" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/key.svg'} alt="" title="key" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/kitchen.svg'} alt="" title="kitchen" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/lightbulb.svg'} alt="" title="lightbulb" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/local_activity.svg'} alt="" title="local_activity" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/local_cafe-1.svg'} alt="" title="local_cafe-1" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/local_cafe.svg'} alt="" title="local_cafe" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/local_mall.svg'} alt="" title="local_mall" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/local_shipping.svg'} alt="" title="local_shipping" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/lock.svg'} alt="" title="lock" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/logout.svg'} alt="" title="logout" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/mail.svg'} alt="" title="mail" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/microwave.svg'} alt="" title="microwave" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/mode_fan.svg'} alt="" title="mode_fan" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/mouse.svg'} alt="" title="mouse" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/move_to_inbox.svg'} alt="" title="move_to_inbox" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/music_note.svg'} alt="" title="music_note" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/new_releases.svg'} alt="" title="new_releases" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/outdoor_grill.svg'} alt="" title="outdoor_grill" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/pediatrics.svg'} alt="" title="pediatrics" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/personal_bag.svg'} alt="" title="personal_bag" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/pets.svg'} alt="" title="pets" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/photo_camera-1.svg'} alt="" title="photo_camera-1" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/photo_camera.svg'} alt="" title="photo_camera" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/print.svg'} alt="" title="print" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/redeem.svg'} alt="" title="redeem" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/request_quote.svg'} alt="" title="request_quote" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/responsive_layout.svg'} alt="" title="responsive_layout" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/restaurant-1.svg'} alt="" title="restaurant-1" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/restaurant.svg'} alt="" title="restaurant" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/screen_rotation.svg'} alt="" title="screen_rotation" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/search.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/self_care.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/sell-1.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/sell.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/skillet.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/spa.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/sports_esports.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/sports_golf.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/sports_hockey.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/sprint.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/star.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/stockpot.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/storefront-1.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/storefront.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/stroller.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/support_agent.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/toys.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/trip.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/tv_gen.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/vacuum.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/watch_screentime.svg'} alt="" /></li>
        <li><img src={process.env.PUBLIC_URL + '/images/icons/merch/work_alert.svg'} alt="" /></li>
      </ul>
    </>
  );
};

export default Icons;
