// src/pages/overview/AppCreativeAssets.js
import React from "react";

import CreativeAssets from "../../pages/resources/CreativeAssets";

const AppCreativeAssets = () => {
  return (
    <>
      <CreativeAssets />
    </>
  );
};

export default AppCreativeAssets;