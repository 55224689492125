// src/pages/WebElevation.js
import React from "react";

import TitleBar from "../../components/TitleBar";

const Elevation = () => {

  const titleBarData = {
    eyebrowText: "Foundation",
    pageTitle: "Elevation",
    figmaLink: "https://www.figma.com/file/WiGbilPYipJQdvp9gNXUY9/1---Colours%2C-Effects-%26-Typography?type=design&node-id=237-310&mode=design"
  };

  return (
    <>
      <TitleBar {...titleBarData} />
      <p className="large">Our design system includes 3 different elevations.</p>

      <div className="columns noWrap">
        <div className="col one_third">
          <h3 className="title-level-3 padding-level-2">Elevation levels</h3>

          <p>Components at different levels behave in the following way:</p>

          <ol>
            <li><strong>Elevation 1</strong>: Used for content to add “emphasis” for example offer cards, content card, quick links, etc.</li>
            <li><strong>Elevation 2</strong>: Used on elements that are not part of the main content and sticky bars (ie. back to top button, sticky nav, etc.).</li>
            <li><strong>Elevation 3</strong>: Highest level of elevation. Used with modals, dialogs which have temporary appearances over all layout elements.</li>
          </ol>
        </div>
        <div className="col two_third">
          <img src={process.env.PUBLIC_URL + '/images/elevation/elevation-web.png'} alt="" />
        </div>
      </div>
    </>
  );
};

export default Elevation;
